import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

export function fv() {
  /* ------------------------
   * 前半アニメーション
   *  ① テキスト１
   *  ② テキスト２
   *  ③ 中央の画像
   *  ④ 前半マスク
  ------------------------ */
  function firstAnimation() {
    // 中央の画像
    gsap.fromTo(
      ".p-top-fv__img",
      { opacity: 1, scale: 1 }, // 初期状態
      {
        y: 10,
        scale: 1.35,
        duration: 2.2,
        ease: "power2.inOut",
      }
    );

    // 大タイトル
    gsap.fromTo(
      ".p-top-fv__title",
      { opacity: 1, scale: 1 },
      {
        opacity: 0,
        scale: 2,
        y: "360px",
        ease: "power3.inOut",
        duration: 2.3,
      }
    );

    // 小タイトル
    gsap.fromTo(
      ".p-top-fv__text",
      { opacity: 1, scale: 1 },
      {
        opacity: 0,
        scale: 2,
        duration: 2.3,
        y: "600px",
        ease: "power3.inOut",
      }
    );

    // 【前半】マスク
    gsap.fromTo(
      ".p-top-cover",
      { "--bg-color": "none", "--z-index": "0", "--opacity": "0" },
      {
        "--bg-color": "#931F26",
        "--z-index": "100",
        "--opacity": "1",
        duration: 1.25,
        delay: 1.2,
        duration: 0.12,
        ease: "power3.out",
        onComplete: lastAnimation,
      }
    );
  }

  /* ------------------------
    後半アニメーション関数まとめ
  ------------------------ */

  // 【後半】マスク
  function lastMaskAnimation() {
    gsap.fromTo(
      ".p-top-cover",
      {
        "--bg-color": "#931F26",
        "--z-index": "100",
        "--opacity": "1",
        duration: 0.12,
      },
      { "--opacity": "0" }
    );
  }

  // Aboutを画面最上部に移動
  function aboutScrollTop() {
    document.querySelector("#topAbout").scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }

  // アニメーションをリセット
  function resetAnimation() {
    gsap.set(".p-top-fv__text", { opacity: 1, scale: 1, y: "0px" });
    gsap.set(".p-top-fv__title", { opacity: 1, scale: 1, y: "0px" });
    gsap.set(".p-top-fv__img", { opacity: 1, scale: 1 });
  }

  // スクロールを無効にする
  function noscroll(e) {
    e.preventDefault();
  }

  // スクロールをロック
  function scrollLock() {
    document.addEventListener("touchmove", noscroll, { passive: false });
    document.addEventListener("wheel", noscroll, { passive: false });
  }

  // スクロールを有効にする
  function scrollUnLock() {
    document.removeEventListener("touchmove", noscroll);
    document.removeEventListener("wheel", noscroll);
  }

  /* --------------------------------
   * 後半アニメーション 
   * setTimeoutでタイミング調整しています
   *  ① Aboutをtopへ
   *  ② 後半マスクを発火
   *  ③ スクロール範囲を削除
   *  ④ スクロールを有効に
   *  ⑤ 全てのアニメーションをリセット
  -------------------------------- */
  function lastAnimation() {
    // Aboutをtopへ
    setTimeout(() => {
      aboutScrollTop();
    }, 420);

    // 後半マスクを発火
    setTimeout(() => {
      lastMaskAnimation();
    }, 880);

    // スクロール範囲を削除
    setTimeout(() => {
      document.querySelector(".scroll").style.display = "none";
    }, 1000);

    // スクロールを有効に
    // 全てのアニメーションをリセット
    setTimeout(() => {
      scrollUnLock();
      resetAnimation();
    }, 1600);
  }

  /* -------------------------------
   * 初回アクセス時のみアニメーションを再生
  ------------------------------- */

  // ロードされる前
  // セッションストレージからフラグを取得
  const isFirstLoad = sessionStorage.getItem("isFirstLoad");
  let loadingWrap = document.querySelector(".c-loading__wrap");

  if (!isFirstLoad) {
    loadingWrap.innerHTML = `
    <div class="c-loading">
        <div class="c-loading__circle"></div>
    </div>
  `;

    setTimeout(() => {
      // セッションストレージにフラグを保存
      sessionStorage.setItem("isFirstLoad", true);
    }, 1000);
  }

  // ページがロードされたら
  window.addEventListener("load", function () {
    // フラグがない場合（初回アクセス時）
    if (!isFirstLoad) {
      // loading
      let loading = document.querySelector(".c-loading");

      setTimeout(() => {
        loading.classList.add("c-loading--close");
      }, 500);

      // 初期状態では非表示のスクロール範囲を表示
      document.querySelector(".scroll").style.display = "block";

      // スクロールを検知してアニメーションを再生（1回のみ）
      document.querySelector(".scroll").addEventListener(
        "scroll",
        () => {
          // スクロールを制御
          scrollLock();

          // アニメーションを実行
          firstAnimation();
        },
        { once: true } // 一度だけ発火させる
      );
    }
  });

  /* ----------------------------------- */
}
